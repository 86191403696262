
/*========================================

** SP Menu (Slide)
========================================*/
jQuery(function($){

	var menu = $('#gnavSP'), // フェードインさせたいメニューを取得
		menuBtn = $('#menuBtn'), // 開くボタンを取得
		closeBtn = $('#closeBtn'), // 閉じるボタンを取得
		body = $(document.body);

	menuBtn.on('click', function(){
		menu.fadeIn();
		body.addClass('opened');
	});

	closeBtn.on('click', function(){
		menu.fadeOut();
		body.removeClass('opened');
  });

	$(window).on('load resize', function(){
		var wid = window.innerWidth;

		if( wid > 768 ){
			if( body.hasClass('opened') ) {
				body.removeClass('opened');
			}
			menu.hide();
		}
	});
});

// クリックでスマホメニューを閉じる
$('#gnavSP a[href]').on('click', function(event) {
  $('#closeBtn').trigger('click');
});





/*========================================

** Header change
========================================*/
window.onload = function() {
	var header = document.querySelector('#header');
	window.addEventListener('scroll', function() {
		var y = window.pageYOffset;
		if (y > 100) {
			header.classList.add('bg');
		} else {
			header.classList.remove('bg');
		}
	});
}



/*========================================

** smooth-scroll
========================================*/

var scroll = new SmoothScroll('a[href*="#"]', {
  header: 'header',
  speed: 400,
  });



/*===============================================

タブ切り替え
==============================================*/
jQuery(function($){
	$('._p-top__contact__tab__item').click(function(){
		$('.is-active').removeClass('is-active');
		$(this).addClass('is-active');
		$('.is-show').removeClass('is-show');
        // クリックしたタブからインデックス番号を取得
		const index = $(this).index();
        // クリックしたタブと同じインデックス番号をもつコンテンツを表示
		$('._p-top__contact__container__item').eq(index).addClass('is-show');
	});
});

function toggleContactFormTab(type) {
  if (type === 'docRequest') {
    $('._p-top__contact__tab__item:nth-of-type(1)').click();
  }
	else if (type === 'contact') {
    $('._p-top__contact__tab__item:nth-of-type(2)').click();
  }
}




/*========================================

** AOS
========================================*/
AOS.init({
    offset: 200,
    duration: 1000,
    // easing: 'ease',
    // delay: 2,
    once: true,
});

/*=====================================

object fit images 発火
========================================*/
objectFitImages();
